.interstitial-container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 3rem;
  background-color: #BD8877;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.interstitial-text {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 3rem;
  font-family: "Varela Round", sans-serif;
  color: white;
  font-weight: lighter;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #622714, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 12s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

.hand-and-boxes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 90%;
}

.hand {
  max-width: 40vw;
  animation: 2s infinite pointy;
}

.boxes {
  max-width: 40vw;
}

@keyframes pointy {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0px, 60px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@media only screen and (max-width: 700px) {
.interstitial-text {
  background-size:100%;
  width: 20rem;
}
}
