.question-container {
  background-color: red;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 5rem; */
}

.cyclist-svg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.kite {
  height: 30vh;
  width: auto;
  position: fixed;
  top: 10%;
  left: 50%;
  animation: 30s infinite alternate flying;
  transition-timing-function: ease-in-out;
  z-index: 0;
}

.loading-kite {
  height: 30vh;
  width: auto;
  position: fixed;
  top: 30%;
  left: 50%;
  animation: 30s infinite alternate flying;
  transition-timing-function: ease-in-out;
  z-index: 0;
}

@keyframes flying {
	0% , 100% {
    transform: translate(0px, 0px) rotate(0deg)
	}
	25% {
    transform: translate(-400px, -20px)

	}
	50% {
    transform: translate(-600px, -25px) rotate(10deg)

	}
	75% {
    transform: translate(-350px, -30px)

	}
}

.statement {
  font-family: "Varela Round", sans-serif;
  color: white;
  font-size: 2rem;
  width: 90%;
  text-align: center;
  z-index: 1;
}

.responses {
  width: 80%;
  z-index: 5;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.response {
  font-family: "Varela Round", sans-serif;
  color: #181818;
  font-size: 1.3rem;
  cursor: pointer;
  border: 0;
  background-color: white;
  width: 100%;
  padding: 1rem;
  margin: 1rem 0 1rem 0;
}

.bottom-line {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.fraction {
  align-self: flex-end;
  font-family: 'Fredoka One', cursive;
  color: white;
  font-size: 5rem;
  font-weight: lighter;
  margin: 0 1.5rem 0 0;
  padding: 0;
  z-index: 5;
}

.logos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0 0 1rem 1rem;
}

.csi {
  height: 7rem;
  margin-right: 0.3rem;
  bottom: 0;
}

@media only screen and (max-width: 700px) {
  .question-container {
    min-height: 100vh;
    height: auto;
  }

  .statement {
    font-size: 1.5rem;
  }

  .responses {
    width: 90%;
  }

  .response {
    margin: 0.5rem 0 0.5rem 0;
  }

  .bottom-line {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .csi {
    height: 6rem;
  }
  .fraction {
    font-size: 3rem;
    margin: 0 1rem 3rem 0;
  }

}
