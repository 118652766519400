.landing-container {
  background-color: #6C8CFE;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;

}
.landing-title {
  font-size: 4rem;
  margin: 0 4rem;
  padding: 0 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.landing-text {
  margin: 0 4rem;
  padding: 0 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 40rem;
  line-height: 2rem;
}
.btn {
  background-color: #6C8CFE;
  border-radius: 20px;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 1.5rem 3rem;
  color: white;
  box-shadow: -4px -4px 20px 0px #90A9FF, 6px 6px 12px 0px #466FFF;
  align-self: flex-end;
  margin: 0 3rem 3rem 0;
}

/*------- HEADER --------*/
.landing-header {
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}
.sun {
  z-index: -1;
  top: 1rem;
  right: 1rem;
  position: absolute;
}
.clouds {
  animation: 35s infinite alternate slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}

/* MOBILE */

@media only screen and (max-width: 700px) {
  .landing-title {
    margin: 1rem;
    font-size: 3.5rem;
    padding: 0;
    align-self: center;
  }
  .landing-text {
    width: 90%;
    margin: 1rem 1rem 2rem 1rem;
    font-size: 1.3rem;
    text-align: justify;
    padding: 0;
    line-height: 2rem;
  }
  .btn {
    align-self: center;
    margin: 0 2rem 5rem 2rem;
    padding: 1.5rem 2.5rem;
  }
  .clouds, .sun  {
    height: 20vh;
    padding: 0;
  }
}
