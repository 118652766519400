body {
  margin: 0;
}

label {
  display: none;
}

h1 {
  font-family: 'Fredoka One', cursive;
  color: white;
  font-size: 6rem;
  font-weight: lighter;
}
p {
  font-family: "Varela Round", sans-serif;
  color: white;
  font-size: 1.3rem;
  font-weight: lighter;
}

a {
  font-family: "Varela Round", sans-serif;
  color: white;
  font-size: 1.1rem;
  text-decoration: none;
}

.btn {
  font-family: 'Fredoka One', cursive;
}

form {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: center;
}

input {
  border: none;
  background-color: white;
  padding: 1rem;
  font-size: 2rem;
  width: 45vw;
  font-family: "Varela Round", sans-serif;
  z-index: 15;
}

input::placeholder {
  font-family: "Varela Round", sans-serif;
}

.csi-logo {
  position: fixed;
  top: 1rem;
  left: 1rem;
  height: 10rem;
  align-items: baseline;
}


@media only screen and (max-width: 700px) {
  input {
    width: 90%;
    align-self: center;
  }

  form {
    width: 90%;
    height: auto;
  }
  .csi-logo {
    top: 0.5rem;
    left: 0.5rem;
    height: 5rem;
  }
}
