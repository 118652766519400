.score-container {
  background-color: #74d47e;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  margin: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
}


/* FORM */
.three-columns {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.left-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.center-column {
  display: flex;
  flex-direction: column;
}

.email-signup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
  flex-grow: 1;
}

.email {
  width: 40vw;
  box-sizing: border-box;
}

.options {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;
}

/* TEXT */

.score-title {
  margin: 0;
}

.score-blurb {
  font-size: 1.2rem;
  width: 40vw;
  border: 4px dotted white;
  padding: 1rem;
  box-sizing: border-box;
  font-weight: bold;
  margin-top: 1rem;
}

.blurb {
  margin-top: 0.5rem;
  width: 40vw;
  font-size: 1rem;
}

.blurb1 {
  width: 40vw;
  font-size: 0.75rem;
}

/* ICONS */
.trophy-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.score {
  position: relative;
  left: 50%;
  font-size: 3rem;
  font-family: "Fredoka One", cursive;
  z-index: 2;
}

.arrow {
  z-index: 0;
  position: relative;
  bottom: 5.5rem;
}


/* LINKS */

.link-btns {
  display: flex;
  flex-direction: row;
}

.link-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0.5rem 0 0.5rem;
  width: 8rem;
}

.btn-link {
  font-size: 1rem;
  margin: 0.5rem 0 0 0;
  text-align: center;
}

.subscribe {
  background-color: #74d47e;
  border: none;
  border-radius: 20px;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 1.5rem 3rem;
  color: white;
  box-shadow: -4px -4px 12px 0px #a0e8a8, 6px 6px 12px 0px #4bb456;
  align-self: flex-end;
  font-family: "Fredoka One", cursive;
  cursor: pointer;
}

.music-btn {
  background-color: #74d47e;
  border: none;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: -4px -4px 12px 0px #a0e8a8, 6px 6px 12px 0px #4bb456;
  cursor: pointer;
}

.note {
  width: 1.5rem;
  height: 1.5rem;
}

.privacy:hover,
.donate:hover {
  text-decoration: underline;
}

/* MESSAGING */
.msg {
  font-size: 1.2rem;
  padding: 0;
  color: blue;
}

.error-msg {
  font-size: 1.2rem;
  padding: 0;
  color: red;
}





/* MOBILE */

@media only screen and (max-width: 1024px) {
  .link-btn {
    margin: 0 0.1rem 0 0.1rem;
    width: 7rem;
  }

  .score-title {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 768px) {
  .score-container {
    padding: 2rem 1rem 2rem 1rem;
    height: auto;
    justify-content: center;
  }

  .three-columns {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .left-column {
    display: none;
  }

  .center-column {
    order: 2;
    max-width: 80%;
  }

  .right-column {
    order: 1;
  }

  .score-title {
    align-self: center;
    font-size: 3rem;
    margin: 0;
  }

  /* TEXT */
  .score-blurb {
    font-size: 1.3rem;
    width: 100%;
    margin: 1rem 0;
  }

  .blurb {
    width: 100%;
    margin: 0 0 1rem 0;
    font-size: 1rem;
    padding: 0;
    line-height: 1.8rem;
    max-width: 95%;
    text-align: justify;
  }
  .blurb1 {
    width: 100%;
    font-size: 0.9rem;
    text-align: justify;
  }

  /* ICONS */
  .trophy {
    width: 200px;
    transform: translate(-50px, 0);
  }
  .score {
    width: 100px;
    transform: translate(-50px, 0);
    font-size: 2rem;
    text-align: center;
  }
  .arrow {
    top: -1rem;
    left: -7rem;
    height: 20vh;
  }


  /* INPUT */
  .email {
    align-self: center;
    width: 100%;
  }
  .subscribe {
    align-self: center;
    margin: 1rem 2rem 2rem 2rem;
    font-size: 1.8rem;
  }

  /* LOWER CONTENT */
  .options {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .link-btns {
    margin: 1rem 0.5rem 1rem 0.5rem;
    order: 3;
  }

}

@media only screen and (max-width: 700px) {
  .center-column {
    max-width: 100%;
  }

}
