.page-container {
  background-color: #ff9a6f;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 3rem 3rem 3rem 7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.name-entry {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.explainer {
  width: 50vw;
  font-family: "Varela Round", sans-serif;
  color: white;
  font-size: 1.3rem;
  z-index: 1;
  line-height: 2rem;
}

.unlock-btn {
  background-color: #ff9a6f;
  filter: drop-shadow(6px 6px 12px #ff7c44) drop-shadow(-4px -4px 12px #ffbb9e);
  color: white;
  border-radius: 20px;
  padding: 1.5rem 3rem;
  cursor: pointer;
  border: none;
  align-self: flex-end;
  font-family: 'Fredoka One', cursive;
  font-size: 1.5rem;
  z-index:5;
}

.lockchain {
  height: 750px;
  position: fixed;
  top: -250px;
  right: 0px;
  z-index: 0;
}


@media only screen and (max-width: 700px) {
  .page-container {
    padding: 3rem 1rem 3rem 1rem;
    justify-content: flex-end;
  }

  input {
    width: 90%;
    align-self: center;

  }

  form {
    width: 100%;
    height: auto;
  }

  .explainer {
    width: 100%;
    font-size: 1.3rem;
    text-align: justify;
  }

  .lockchain {
    height: 400px;
    top: -150px;
    right: 0px;
    z-index: 0;
  }

  .unlock-btn {
    align-self: center;
    margin: 1.5rem 1rem 1.5rem 1rem;
  }

}
